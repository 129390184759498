import React from "react";
import "./styles/Subiecte.css";
import { Footer } from "../components";

const Subiecte = () => {
  return (
    <>
      <div className="bg2">
        <div className="banner">
          <img className="Logo-ATL" src={require("./assets/ATLaurian.webp")} />
          <p className="npm">
            Concursul Național de Informatică Urmașii lui Moisil
          </p>
          <img className="Logo-sepi" src={require("./assets/sepi.webp")} />
        </div>
        <h1 className="title-participanti">Subiecte</h1>
      </div>
      <div className="divider1"></div>
      <div className="subiecte-page">
        <h2>
          Concursul Naţional de Informatică “Urmaşii lui Moisil” se desfăşoară
          într-o singură zi şi constă într-o probă practică. Durata de
          desfășurare a probei practice este de trei ore. Proba practică constă
          în rezolvarea a două probleme de natură algoritmică, cu ajutorul
          calculatorului. Rezolvarea unei probleme presupune elaborarea unui
          program eficient din punct de vedere computaţional.
        </h2>

        <table style={{border:'none',}}>
          <thead>
            <tr>
              <th className="th-rez">Descriere</th>
              <th className="th-rez">Enunț</th>
              <th className="th-rez">Soluții</th>
              <th className="th-rez">Teste</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-hov">
                <a className="button-sub" href="/9_descriere.pdf" download>
                    Clasa IX
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/9_loto.pdf" download>
                    Clasa IX loto
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/9-loto.cpp" download>
                    Clasa IX loto
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/9-loto_teste.zip" download>
                    Clasa IX loto
                </a>
              </td>
            </tr>

            <tr>
              <td className="td-hov"> </td>

              <td className="td-hov">
                <a className="button-sub" href="/9_sah.pdf" download>
                    Clasa IX sah
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/9-sah.cpp" download>
                    Clasa IXs sah
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/9-sah_teste.zip" download>
                    Clasa IXs sah
                </a>
              </td>
            </tr>

            <tr>
              <td className="td-hov">
                <a className="button-sub" href="/10_descriere.pdf" download>
                    Clasa X
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/10_indiciu.pdf" download>
                    Clasa X indiciu
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/10_indiciu_100.cpp" download>
                    Clasa X indiciu
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/10_indiciu_teste.zip" download>
                    Clasa X indiciu
                </a>
              </td>
            </tr>
            <tr>
              <td className="td-hov"></td>

              <td className="td-hov">
                <a className="button-sub" href="/10_planor.pdf" download>
                    Clasa X planor
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/10_planor_100.c" download>
                    Clasa X planor
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/10_planor_teste.zip" download>
                    Clasa X planor
                </a>
              </td>
            </tr>
            <tr>
              <td className="td-hov">
                {" "}
                <a className="button-sub" href="/112_descriere.pdf" download>
                    Clasa XI-XII
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/112_perioade.pdf" download>
                    Clasa XI-XII perioade
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/112_perioade.cpp" download>
                    Clasa XI-XII perioade
                </a>
              </td>

              <td className="td-hov">
                <a
                  className="button-sub"
                  href="/112_perioade_teste.zip"
                  download
                >
                    Clasa XI-XII perioade
                </a>
              </td>
            </tr>

            <tr>
              <td className="td-hov"></td>

              <td className="td-hov">
                <a className="button-sub" href="/112_wd.pdf" download>
                    Clasa XI-XII wd
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/112_wd.cpp" download>
                  Clasa XI-XII wd
                </a>
              </td>

              <td className="td-hov">
                <a className="button-sub" href="/112_wd_teste.zip" download>
                  Clasa XI-XII wd
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Footer />
    </>
  );
};

export default Subiecte;
