import './styles/NoPage.css'
import { React } from "react";


const NoPage = () => {
    return (
      <>
        <h1>404</h1>
      </>
    )
  };
  
  export default NoPage;