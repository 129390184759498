import React from "react";
import "./styles/Rezultate.css";
import Footer from "../components/Footer";
import { useState } from "react";

const Rezulate = () => {
  const [participants, setParticipants] = useState([
    {
      numar: "1",
      nume: "SAVU STEFAN CATALIN",
      clasa: "9",
      scoala: "Colegiul National ”I.L.Caragiale” Ploiești",
      judetul: "PRAHOVA",
      premiul: "TROFEUL ”URMAȘII LUI MOISIL”",
      suma: "1000",
      nrDiploma: "216/25.03.2023",
    },
    {
      numar: "2",
      nume: "ALIN POPESCU",
      clasa: "9",
      scoala: "Liceul Teoretic Orizont-Chișinău",
      judetul: "REPUBLICA MOLDOVA",
      premiul: "II",
      suma: "700",
      nrDiploma: "217/25.032023",
    },
    {
      numar: "3",
      nume: "DESPA FABIAN-STEFAN",
      clasa: "9",
      scoala:
        "Palatul Copiilor Craiova, Centrul de Pregătire pentru Performanță în Informatică Craiova",
      judetul: "DOLJ",
      premiul: "III",
      suma: "500",
      nrDiploma: "218/25.032023",
    },
    {
      numar: "4",
      nume: "DOBRE DARIUS",
      clasa: "9",
      scoala: "Colegiul Național de Informatică „Matei Basarab” Râmnicu Vâlcea",
      judetul: "VÂLCEA",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "219/25.032023",
    },
    {
      numar: "5",
      nume: "TOMITA MATEI ALEXANDRU",
      clasa: "9",
      scoala: "Colegiul Național Emil Racoviță Iași",
      judetul: "IAȘI",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "220/25.032023",
    },
    {
      numar: "6",
      nume: "VARTIC RIHARD",
      clasa: "9",
      scoala: "Liceul Teoretic Internațional de Informatică București",
      judetul: "BUCUREȘTI",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "221/25.032023",
    },
    {
      numar: "7",
      nume: "POINARU RARES-AUREL",
      clasa: "9",
      scoala: "Colegiul Național ”Stefan cel Mare” Suceava",
      judetul: "SUCEAVA",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "222/25.032023",
    },
    {
      numar: "8",
      nume: "ARSENOIU IULIAN GEORGE",
      clasa: "10",
      scoala: "Colegiul National ”I.L.Caragiale” Ploiesti",
      judetul: "PRAHOVA",
      premiul: "I",
      suma: "1000",
      nrDiploma: "223/25.032023",
    },
    {
      numar: "9",
      nume: "TUDOSE RARES-FELIX",
      clasa: "10",
      scoala: "Liceul Teoretic Internațional De Informatică București",
      judetul: "BUCUREȘTI",
      premiul: "II",
      suma: "700",
      nrDiploma: "224/25.032023",
    },
    {
      numar: "10",
      nume: "LUCHIAN CRISTIAN",
      clasa: "10",
      scoala: "Colegiul Național de Informatică, Piatra-Neamț",
      judetul: "NEAMȚ",
      premiul: "III",
      suma: "500",
      nrDiploma: "225/25.032023",
    },
    {
      numar: "11",
      nume: "PUICA ANDREI",
      clasa: "10",
      scoala: "Liceul Teoretic 'Emil Racoviță' Vaslui",
      judetul: "VASLUI",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "226/25.032023",
    },
    {
      numar: "12",
      nume: "COZMA TIBERIU STEFAN",
      clasa: "10",
      scoala: "Liceul Teoretic de Informatică Grigore Moisil Iași",
      judetul: "IAȘI",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "227/25.032023",
    },
    {
      numar: "13",
      nume: "MITRI ROBERT CRISTIAN",
      clasa: "10",
      scoala:
        "Colegiul Național 'Frații Buzești', Centrul de Pregătire pentru Performanță în Informatică Craiova",
      judetul: "DOLJ",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "228/25.032023",
    },
    {
      numar: "14",
      nume: "IACOB TUDOR",
      clasa: "10",
      scoala: "Colegiul National „Unirea” Târgu Mureș",
      judetul: "MUREȘ",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "229/25.032023",
    },
    {
      numar: "15",
      nume: "DOBLEAGA ALEXANDRU",
      clasa: "11",
      scoala: "Colegiul National ”I.L.Caragiale” Ploiesti",
      judetul: "PRAHOVA",
      premiul: "I",
      suma: "1000",
      nrDiploma: "230/25.032023"
    },
    {
      numar: "16",
      nume: "BOACA ANDREI",
      clasa: "11",
      scoala: "Colegiul Național Emil Racoviță Iași",
      judetul: "IAȘI",
      premiul: "II",
      suma: "700",
      nrDiploma: "231/25.032023"
    },
    {
      numar: "17",
      nume: "TIMUR DEGTEARI",
      clasa: "11",
      scoala: "Liceul Teoretic Orizont Chișinău",
      judetul: "REPUBLICA MOLDOVA",
      premiul: "III",
      suma: "500",
      nrDiploma: "232/25.032023"
    },
    {
      numar: "18",
      nume: "ISPIR ALEXANDRU",
      clasa: "11",
      scoala: "Colegiul Național ”Stefan cel Mare” Suceava",
      judetul: "SUCEAVA",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "233/25.032023"
    },
    {
      numar: "19",
      nume: "IORDACHIOAIEI ALEXANDRU",
      clasa: "11",
      scoala: "Colegiul Național ”M. Eminescu” Botoșani",
      judetul: "BOTOȘANI",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "234/25.032023"
    },
    {
      numar: "20",
      nume: "CHIPER ALEX",
      clasa: "11",
      scoala: "Colegiul Național ”Mihai Viteazul” Sfântu Gheorghe",
      judetul: "COVASNA",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "235/25.032023"
    },
    {
      numar: "21",
      nume: "ION ANDREI ROBERT",
      clasa: "12",
      scoala: "Liceul Teoretic Internațional de Informatică București",
      judetul: "BUCUREȘTI",
      premiul: "I",
      suma: "1000",
      nrDiploma: "236/25.032023"
    },
    {
      numar: "22",
      nume: "LORINTZ ALEXANDRU",
      clasa: "12",
      scoala: "Colegiul Național ”Lucian Blaga” Sebeș",
      judetul: "ALBA",
      premiul: "II",
      suma: "700",
      nrDiploma: "237/25.032023"
    },
    {
      numar: "23",
      nume: "ALEXANDRU ANDREI",
      clasa: "12",
      scoala: "Liceul Teoretic Orizont-Chișinău",
      judetul: "REPUBLICA MOLDOVA",
      premiul: "III",
      suma: "500",
      nrDiploma: "238/25.032023"
    },
    {
      numar: "24",
      nume: "RADUCU IOAN STEFAN",
      clasa: "12",
      scoala: "Colegiul Național „Mircea cel Bătrân” Rm. Vâlcea",
      judetul: "VÂLCEA",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "239/25.032023"
    },
    {
      numar: "25",
      nume: "BALICA ANDREI SEBASTIAN",
      clasa: "12",
      scoala: "Colegiul Național ”Constantin Carabella” Târgoviște",
      judetul: "DÂMBOVIȚA",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "240/25.032023"
    },
    {
      numar: "26  ",
      nume: "COTIGĂ DAVID-GABRIEL",
      clasa: "12",
      scoala: "Colegiul Național „Gheorghe Munteanu Murgoci” Brăila",
      judetul: "BRĂILA",
      premiul: "MENȚIUNE",
      suma: "300",
      nrDiploma: "241/25.032023"
    }
  ]);
  return (
    <>
      <div className="bg2">
        <div className="banner">
          <img className="Logo-ATL" src={require("./assets/ATLaurian.webp")} />
          <p className="npm">
            Concursul Național de Informatică Urmașii lui Moisil
          </p>
          <img className="Logo-sepi" src={require("./assets/sepi.webp")} />
        </div>
        <h1 style={{ fontSize: "4vw" }} className="title-participanti">
          Rezultate
        </h1>
      </div>
      <div className="divider1"></div>
      <h2 style={{ paddingLeft: "5%", textAlign:"start" }} className="content1">
      PREMIANȚI CONCURSUL NAŢIONAL "URMAŞII LUI MOISIL", 25 martie 2023:
      </h2>
      <table className="table1">
        <thead>
          <tr>
            <th>Numar</th>
            <th>Nume</th>
            <th>Clasa</th>
            <th>Școala</th>
            <th>Județul</th>
            <th>Premiul</th>
            <th>Suma(RON)</th>
            <th>Nr.Diplomă</th>
          </tr>
        </thead>
        <tbody>
          {participants.map((participant) => (
            <tr className="Label" key={participant.id}>
              <td>{participant.numar}</td>
              <td>{participant.nume}</td>
              <td>{participant.clasa}</td>
              <td>{participant.scoala}</td>
              <td>{participant.judetul}</td>
              <td>{participant.premiul}</td>
              <td>{participant.suma}</td>
              <td>{participant.nrDiploma}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="premii-botttom">
        <div className="card">
          <p>Inspector Școlar General ISJ BOTOȘANI</p>
          <p>PREȘEDINTELE COMISIEI</p>
          <p>Bogdan Gheorghe SURUCIUC</p>
        </div>
        <div className="card">
          <p>Director</p>
          <p>C. N. ”A. T. LAURIAN” BOTOȘANI</p>
          <p>Profesor Ioan ONOFREI</p>
        </div>
        <div className="card">
          <p>Președinte – comisia științifică</p>
          <p>SOCIETATEA PENTRU EXCELENȚĂ ȘI PERFORMANȚĂ ÎN INFORMATICĂ</p>
          <p>Profesor Adrian PANAETE</p>
        </div>
      </div>
      <br />
      <h2 style={{ paddingLeft: "5%" }} className="content1">
        Rezultate contestații:
      </h2>
      <table class="GeneratedTable">
        <thead>
          <tr>
            <th>ID</th>
            <th>Problema contestată </th>
            <th>Punctaj iniţial</th>
            <th>Punctaj fina</th>
            <th>Rezultat</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>10_O_35 </td>
            <td>indiciu </td>
            <td>96 </td>
            <td>96 </td>
            <td>Punctajul se menţine</td>
          </tr>
          <tr>
            <td>9_O_21 </td>
            <td>sah</td>
            <td>4</td>
            <td>4</td>
            <td>Punctajul se menţine</td>
          </tr>
        </tbody>
      </table>

      <br />
      <br />

      <h2 style={{ paddingLeft: "5%" }} className="content1">
        Rezultate contestații:
      </h2>
      <body className="rezultate">
        <div className="cards">
          <h5 className="">Rezultate clasa IX</h5>
          <a className="button-sub" href="/9.pdf" download>
            • Rezultate Clasa IX
          </a>
        </div>
        <div className="cards">
          <h5 className="">Rezultate clasa X</h5>
          <a className="button-sub" href="/10.pdf" download>
            • Rezultate Clasa X
          </a>
        </div>
        <div className="cards">
          <h5 className="">Rezultate clasa XI</h5>
          <a className="button-sub" href="/11.pdf" download>
            • Rezultate Clasa XI
          </a>
        </div>
        <div className="cards">
          <h5 className="">Rezultate clasa XII</h5>
          <a className="button-sub" href="/12.pdf" download>
            • Rezultate Clasa XII
          </a>
        </div>
      </body>
      <Footer />
    </>
  );
};

export default Rezulate;
